import { combineReducers } from 'redux';
import { reducer as oidc, UserState as OIDCState } from 'redux-oidc';
import {
  features,
  myapps,
  MyAppsState,
  notifications,
  NotificationsState,
  org,
  OrgState,
  published,
  PublishedState,
  session,
  SessionState,
  sites,
  SitesState,
  ui,
  UIState,
  responsive as browser,
  ResponsiveState,
  provisioningStores,
  ProvisioningStoreState,
  userSites,
  UserSites,
  FeaturesState,
  idps,
  IdpState,
  alerts,
  AlertState,
  undoabletodos,
  site,
  SiteState,
  forms,
  FormsState,
  events,
  EventState,
  pageTemplates,
  PageTemplatesState,
  tenancies,
  TenanciesState,
} from 'shared/state/ducks';

export interface RootState {
  features: FeaturesState;
  myapps: MyAppsState;
  notifications: NotificationsState;
  org: OrgState;
  published: PublishedState;
  session: SessionState;
  sites: SitesState;
  site: SiteState;
  ui: UIState;
  oidc: OIDCState;
  browser: ResponsiveState;
  provisioningStores: ProvisioningStoreState;
  userSites: UserSites;
  idps: IdpState;
  alerts: AlertState;
  undoabletodos: any;
  forms: FormsState;
  events: EventState;
  pageTemplates: PageTemplatesState;
  tenancies: TenanciesState;
}

export const rootReducer = combineReducers<RootState>({
  features,
  myapps,
  notifications,
  org,
  published,
  session,
  sites,
  ui,
  oidc,
  browser,
  provisioningStores,
  userSites,
  idps,
  alerts,
  undoabletodos,
  site,
  forms,
  events,
  pageTemplates,
  tenancies,
});
