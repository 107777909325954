import React, { useEffect } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { SettingsContext } from './context/SettingsContext';
import EditorUI from './EditorUI';
import EditorNodes from './nodes/EditorNodes';
import { TableContext } from './plugins/TableHeaderPlugin';
import EditorTheme from './theme/EditorTheme';
import { ExtendedTextNode } from './nodes/ExtendableTextNode';
import { TextNode } from 'lexical';
import { debounce } from 'lodash';

function RichTextEditor(props): JSX.Element {
  const { editing, widgetState, siteId } = props;
  const [value, setValue] = React.useState(widgetState.config.content);
  const [isEditingRichText, setIsEditingRichText] = React.useState(
    widgetState.config.isEditingRichText
  );

  const debouncedUpdateConfig = React.useCallback(
    debounce((widgetId, config) => {
      props.updateCurrentPageWidgetConfig(widgetId, config);
    }, 300),
    [props.updateCurrentPageWidgetConfig]
  );

  useEffect(() => {
    if (widgetState.config.content !== value) {
      debouncedUpdateConfig(widgetState.id, {
        ...widgetState.config,
        content: value,
      });
    }
  }, [value, widgetState.id, widgetState.config, debouncedUpdateConfig]);

  useEffect(() => {
    if (widgetState.config.isEditingRichText !== isEditingRichText) {
      props.updateCurrentPageWidgetConfig(widgetState.id, {
        ...widgetState.config,
        isEditingRichText,
      });
    }
  }, [isEditingRichText, widgetState.id, widgetState.config]);

  const initialConfig = {
    editorState: undefined,
    editable: editing && isEditingRichText, // disable when not in editing mode
    namespace: 'Playground',
    nodes: [
      ...EditorNodes,
      // ExtendedTextNode is a custom node that extends the TextNode and applies styles that are defined in the html
      // string. Without this, Lexical will simply serialize the html string to Lexical data and use plain text.
      ExtendedTextNode,
      {
        replace: TextNode,
        with: (node: TextNode) => new ExtendedTextNode(node.__text),
        withKlass: ExtendedTextNode,
      },
    ],
    onError: (error: Error) => {
      throw error;
    },
    theme: EditorTheme,
  };

  // These styles are used to position the editor in the correct location when the editor is in
  // isEditingRichText mode because the editor needs to not be rendered inside the WidgetWrapper
  // while working in the interface due to re-render issues.
  const editorShellStyles: React.CSSProperties =
    isEditingRichText && editing
      ? {
          position: 'absolute',
          top: widgetState.config.yLocation,
          left: widgetState.config.xLocation,
          maxWidth: widgetState.config.width,
          minWidth: widgetState.config.width,
        }
      : {};

  return (
    <span data-test-id="rich-text-editor">
      <LexicalComposer initialConfig={initialConfig}>
        <SettingsContext>
          <SharedHistoryContext>
            <TableContext>
              <div className="editor-shell" style={editorShellStyles}>
                <EditorUI
                  editable={editing}
                  value={value}
                  onChange={(newValue: React.SetStateAction<string>) => {
                    setValue(newValue);
                  }}
                  handleEditingRichText={() => {
                    setIsEditingRichText(!isEditingRichText);
                  }}
                  isEditingRichText={isEditingRichText}
                  siteId={siteId}
                  widgetConfig={widgetState.config}
                />
              </div>
            </TableContext>
          </SharedHistoryContext>
        </SettingsContext>
      </LexicalComposer>
    </span>
  );
}

export default RichTextEditor;
