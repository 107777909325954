import React, { useState, MouseEvent, ChangeEvent, useRef } from 'react';
import {
  Panel,
  Label,
  FormControl,
  Select,
  Card,
  Heading,
  Button,
  TextInput,
  TextArea,
} from '@uitk/react';
import styled from 'styled-components';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import { Popover } from '@material-ui/core';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  BackgroundData,
  Background,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import CalendarColorSelector from './CalendarColorSelector';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';
import { Link } from 'link-ui-react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EventList from './EventList';
import dayjs from 'dayjs';
import { saveEvent, updateEvent, uploadBulkEvents } from 'shared/api/events';
import { toast } from 'react-toastify';
import { AddIcon, InfoOutlineIcon as InfoOutlined } from '@uitk/react-icons';
import { FileDownloadIcon } from '@uitk/react-icons';
import Stack from '@mui/material/Stack';

const StyledPanel = styled.div`
  & button[class^='Panelcomponent__HeaderSection'] {
    height: 2.5em;
    background: rgba(141, 145, 160, 0.07);
    border-radius: 0;
    &: hover,&: focus {
      box-shadow: none;
    }
  }
  & span[class^='Panelcomponent__HeaderMainContent'] {
    height: 2.5em;
  }
  & div[class^='uitk-panel Panelcomponent__Panel'] {
    margin-bottom: 0rem;
    border: none;
  }
  & div[class^='Panelcomponent__Content'] {
    padding-top: 0rem;
  }
`;
const StyledButton = styled(Button)`
  margin: 5px;
`;

const FontWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const FontSelect = styled(Select)`
  font-family: OptumSans, sans-serif;
  width: 9.375rem;
  margin-left: 0.6rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

const MainLabelStyles = styled(Label)`
  color: #1d1929;
  font-size: 14px;
  font-family: OptumSans, sans-serif;
  font-weight: 500;
  padding-right: 0.5rem;
`;

const StyledPanelTitle = {
  'font-family': 'OptumSans',
  'font-size': '14px',
  color: '#1d1929',
  'font-weight': '700',
  'margin-left': '0.8em',
};

const CustomLink = styled(Link)`
  font-family: 'OptumSans';
  font-size: 2rem;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px #888888;
  color: ${p => p.theme.colors.aux.darkBlue};
  &:hover {
    color: ${p => p.theme.colors.aux.darkBlue};
  }
`;

const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const ManageEventPopover = styled(Popover)`
  margin-top: 100px;
  .MuiPaper-root {
    overflow-x: unset;
    overflow-y: unset;
    margin-left: -30px;
    width: 645px; /* Match the width of StyledCard */
  }
`;
const StyledCard = styled(Card)`
  & div[class^='Cardcomponent__Content'] {
    padding: 1rem;
  }
  height: 100%; /* Add this line to make the card fit the height of its parent div */
  width: 645px;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem; /* Added margin */
`;
const HeaderContainerImport = styled.div`
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem; /* Added margin */
`;
const StyledHeading = styled(Heading)`
  color: black;
  margin-right: auto;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem; /* Added margin */
`;

const StyledBullet = styled.li`
  font-family: 'OptumSans';
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  list-style-type: disc;
  margin-left: 20px;
`;
const CirclePoint = styled.div`
  font-family: 'OptumSans';
  width: 35px;
  height: 35px;
  background-color: rgb(25, 110, 207);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  margin-right: 20px;
`;
const StyledImportHeadingText = styled.div`
  margin-left: 1rem;
  font-family: 'OptumSans';
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
`;
const Container = styled.div`
  display: flex;
  align-items: center; /* Align items vertically centered */
  margin: 1px;
  margin-left: 1rem;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
`;

const BrowseContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px;
`;
const StyledTextHeadingDownload = styled.div`
  font-family: 'OptumSans';
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
`;
const StyledText = styled.div`
  font-family: 'OptumSans';
  color: #899198;
  font-size: 16px;
  margin: 0;
`;

const StyledLink = styled.a`
  color: rgb(0, 0, 255);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const HeadingImport = styled.h2`
  font-family: 'OptumSans';
  color: #253c8d;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding-left: 1rem;
`;
const ParentContainer = styled.div`
  display: flex;
`;
const BorderContainer = styled.div`
  margin-left: 1rem;
  border: 1rem;
  background-color: rgba(25, 110, 207, 1);
  width: 5px;
`;

const HeadingContainer = styled.div`
  padding: 1rem;
  font-size: 16px;
  background-color: rgba(25, 110, 207, 0.047058823529411764);
`;
const CalendarWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;
  const [manageEvents, setManageEvents] = useState(false);
  const [addEvent, setAddEvent] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [importEventEnabled, setImportEventEnabled] = useState(false);
  const [eventId, setEventId] = useState<number | null>(null);
  const [
    eventSectionAnchorEl,
    setEventSectionAnchorEl,
  ] = useState<SVGElement | null>(null);
  const editSectionOpen = Boolean(eventSectionAnchorEl);

  const editItemId = editSectionOpen ? 'edit-link-popover' : undefined;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popOveropen = Boolean(anchorEl);

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: {
        ...value.backgroundData,
        color: data,
      },
    });
  };
  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };
  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };
  const handleCalendarAccentColor = (data: string) => {
    onChange({
      ...value,
      calendarAccentColor: data,
    });
  };

  const handleFontFamily = (data: string) => {
    onChange({
      ...value,
      fontFamily: data,
    });
  };

  const handleFontChange = event => {
    handleFontFamily(event.target.value);
  };
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setManageEvents(true);
    setAddEvent(false);
    setEditEvent(false);
    setImportEventEnabled(false);
    setAnchorEl(event.currentTarget);
    props.fetchEventList(widgetState.id);
  };
  const handlePopoverClose = () => {
    setEventPayload({
      eventTitle: '',
      eventDescription: '',
      location: '',
      url: '',
      startDate: null,
      endDate: null,
    });
    setAnchorEl(null);
  };

  const handleClose = (e: MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setEventSectionAnchorEl(null);
  };

  const handleImportEvent = () => {
    setImportEventEnabled(true);
    setManageEvents(false);
    setAddEvent(false);
    setEditEvent(false);
  };

  const handleAddEvent = () => {
    setManageEvents(false);
    setAddEvent(true);
    setEditEvent(false);
    setImportEventEnabled(false);
  };
  const handleEditEvent = (event: EventData) => {
    setImportEventEnabled(false);
    setManageEvents(false);
    setAddEvent(false);
    setEditEvent(true);
    setEventPayload({
      eventTitle: event.name,
      eventDescription: event.description,
      location: event.location,
      url: event.eventUrl,
      startDate: event.startTime,
      endDate: event.endTime,
    });
    setEventId(event.id);
  };
  const [eventPayload, setEventPayload] = useState({
    eventTitle: '',
    eventDescription: '',
    location: '',
    url: '',
    startDate: null,
    endDate: null,
  });
  const {
    eventTitle,
    eventDescription,
    location,
    url,
    startDate,
    endDate,
  } = eventPayload;
  const handleSubmit = async () => {
    const saveEventPayload: EventPayload = {
      name: eventTitle,
      description: eventDescription,
      location: location,
      eventUrl: url,
      startTime: startDate
        .toISOString()
        .split('.')[0]
        .replace('Z', ''),
      endTime: endDate
        .toISOString()
        .split('.')[0]
        .replace('Z', ''),
      widgetId: widgetState.id,
    };
    const queryParams = {
      siteId: props.site.id,
      pageId: props.currentPage.pageId,
    };

    saveEvent(saveEventPayload, queryParams.siteId, queryParams.pageId)
      .then(() => {
        toast('Event saved successfully!', {
          type: 'success',
          theme: 'colored',
        });
        setEventPayload({
          eventTitle: '',
          eventDescription: '',
          location: '',
          url: '',
          startDate: null,
          endDate: null,
        });
        handlePopoverClose();
      })
      .catch(error => {
        // Handle any errors that occur during saveEvent execution
        console.error('Error saving event:', error);
        toast('Error saving event.', { type: 'error', theme: 'colored' });
      });
  };

  const handleUpdateEvent = () => {
    const updateEventPayload: EventPayload = {
      name: eventTitle,
      description: eventDescription,
      location: location,
      eventUrl: url,
      startTime: new Date(startDate)
        .toISOString()
        .split('.')[0]
        .replace('Z', ''),
      endTime: new Date(endDate)
        .toISOString()
        .split('.')[0]
        .replace('Z', ''),
      widgetId: widgetState.id,
    };

    updateEvent(eventId, updateEventPayload)
      .then(() => {
        toast('Event updated successfully!', {
          type: 'success',
          theme: 'colored',
        });
        setEventPayload({
          eventTitle: '',
          eventDescription: '',
          location: '',
          url: '',
          startDate: null,
          endDate: null,
        });
        handlePopoverClose();
      })
      .catch(error => {
        // Handle any errors that occur during updateEvent execution
        console.error('Error updating event:', error);
        toast('Error updating event.', { type: 'error', theme: 'colored' });
      });
  };
  function handleInputClick(event: MouseEvent<HTMLInputElement>): void {
    event.stopPropagation();
  }
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      // Process the file here
    }
  };
  const handleBulkSaveClick = () => {
    const queryParams = {
      siteId: props.site.id,
      pageId: props.currentPage.pageId,
    };
    uploadBulkEvents(
      widgetState.id,
      queryParams.siteId,
      queryParams.pageId,
      selectedFile
    )
      .then(() => {
        toast('Events uploaded successfully!', {
          type: 'success',
          theme: 'colored',
        });
        setSelectedFile(null);
        handlePopoverClose();
      })
      .catch(error => {
        // Handle any errors that occur during uploadBulkEvents execution
        console.error('Error uploading events:', error);
        toast('Error uploading events.', { type: 'error', theme: 'colored' });
      });
  };
  const renderEventForm = (title: string) => (
    <div onClick={handleInputClick}>
      <StyledCard>
        <HeaderContainer>
          <StyledHeading level="h4">{title}</StyledHeading>
        </HeaderContainer>
        <FormWrapper>
          <form>
            <FormControl id="required-text-input-1" className="mt-m" required>
              <Label> Event Title:</Label>
              <TextInput
                value={eventTitle}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEventPayload({
                    ...eventPayload,
                    eventTitle: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl id="required-text-input-2" required>
              <Label> Event Description:</Label>
              <TextArea
                value={eventDescription}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEventPayload({
                    ...eventPayload,
                    eventDescription: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl id="required-text-input-3">
              <Label> Location:</Label>
              <TextInput
                value={location}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEventPayload({
                    ...eventPayload,
                    location: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl id="required-text-input-4">
              <Label> URL:</Label>
              <TextInput
                value={url}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEventPayload({
                    ...eventPayload,
                    url: e.target.value,
                  })
                }
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack sx={{ flexDirection: 'row', marginTop: '1rem' }}>
                <DateTimePicker
                  label="Start Date"
                  value={
                    addEvent
                      ? startDate
                      : editEvent
                      ? dayjs(new Date(startDate))
                      : null
                  }
                  sx={{ margin: '0px' }}
                  onChange={newValue =>
                    setEventPayload({
                      ...eventPayload,
                      startDate: newValue,
                    })
                  }
                />
                <DateTimePicker
                  label="End Date"
                  value={
                    addEvent
                      ? endDate
                      : editEvent
                      ? dayjs(new Date(endDate))
                      : null
                  }
                  sx={{ marginLeft: '14px' }}
                  minDate={
                    addEvent
                      ? startDate
                      : editEvent
                      ? dayjs(new Date(startDate))
                      : null
                  }
                  onChange={newValue =>
                    setEventPayload({
                      ...eventPayload,
                      endDate: newValue,
                    })
                  }
                />
              </Stack>
            </LocalizationProvider>
          </form>
        </FormWrapper>
        <ButtonContainer>
          <StyledButton
            variant="primary"
            className="mr-xs mt-s"
            onPress={
              addEvent
                ? handleSubmit
                : editEvent
                ? handleUpdateEvent
                : undefined
            }
          >
            Save
          </StyledButton>
          <StyledButton variant="secondary" onPress={handlePopoverClose}>
            Cancel
          </StyledButton>
        </ButtonContainer>
      </StyledCard>
    </div>
  );
  return (
    <div>
      <Panel.Group data-test-id="calendar-widget-control">
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="background-panel"
          >
            <CustomLink underline onClick={handlePopoverOpen}>
              Manage Events
            </CustomLink>
            <ManageEventPopover
              id={editItemId}
              open={popOveropen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorReference="anchorEl"
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              data-test-id="edit-link-popover"
            >
              {manageEvents && (
                <>
                  <StyledCard>
                    <HeaderContainer>
                      <StyledHeading level="h4">Manage Events</StyledHeading>
                      <HeaderButtonContainer>
                        <StyledButton
                          variant="tertiary"
                          onPress={handleImportEvent}
                          icon={<FileDownloadIcon />}
                          iconLeft
                        >
                          Import Events
                        </StyledButton>
                        <StyledButton
                          variant="tertiary"
                          onPress={handleAddEvent}
                          icon={<AddIcon />}
                          iconLeft
                        >
                          Add Event
                        </StyledButton>
                      </HeaderButtonContainer>
                    </HeaderContainer>
                    {props.eventList.length === 0 && (
                      <>
                        <HeadingContainer>
                          <span style={{ verticalAlign: 'middle' }}>
                            <InfoOutlined />
                          </span>
                          You haven't added any events to your calendar yet
                        </HeadingContainer>
                      </>
                    )}
                    <EventList
                      eventList={props.eventList}
                      removeEvent={props.removeEvent}
                      onEditEvent={handleEditEvent}
                    />
                    <ButtonContainer>
                      <Button variant="secondary" onPress={handlePopoverClose}>
                        Cancel
                      </Button>
                    </ButtonContainer>
                  </StyledCard>
                </>
              )}
              {!manageEvents && addEvent && renderEventForm('Add Event')}
              {!manageEvents && editEvent && renderEventForm('Edit Event')}
              {importEventEnabled && !manageEvents && (
                <div onAbort={handleInputClick}>
                  <StyledCard>
                    <HeaderContainerImport>
                      <StyledHeading level="h4">Import Events</StyledHeading>
                    </HeaderContainerImport>
                    <StyledImportHeadingText>
                      Importing events allows you to import many events at one
                      time from a pre-made template.
                    </StyledImportHeadingText>
                    <ParentContainer>
                      <BorderContainer />
                      <HeadingContainer>
                        <HeadingImport> Tips for Best Results</HeadingImport>
                        <StyledBullet>
                          Only import the template that we have provided for
                          you. Any other templates will not work.
                        </StyledBullet>
                        <StyledBullet>
                          All template fields should have data in them. A field
                          that is missing data may not be added successfully.
                        </StyledBullet>
                      </HeadingContainer>
                    </ParentContainer>

                    <Container>
                      <CirclePoint>1</CirclePoint>
                      <InnerContainer>
                        <StyledTextHeadingDownload>
                          Download Template
                        </StyledTextHeadingDownload>
                        <StyledText>
                          Get our file template by
                          <StyledLink
                            href="https://cdn-stage.linkhealth.com/site-builder/horizon/bulk_events_template.xlsx"
                            download
                          >
                            {' '}
                            clicking here
                          </StyledLink>
                          .
                        </StyledText>
                      </InnerContainer>
                    </Container>
                    <Container>
                      <CirclePoint>2</CirclePoint>
                      <InnerContainer>
                        <StyledTextHeadingDownload>
                          Import Completed Template
                        </StyledTextHeadingDownload>
                        <BrowseContainer>
                          <TextInput
                            type="text"
                            value={selectedFile ? selectedFile.name : ''}
                            readOnly
                            style={{ 'margin-right': '1rem' }}
                          />
                          <Button
                            variant="tertiary"
                            onPress={handleBrowseClick}
                          >
                            Browse
                          </Button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept=".xls,.xlsx"
                            onChange={handleFileChange}
                          />
                        </BrowseContainer>
                      </InnerContainer>
                    </Container>
                    <ButtonContainer>
                      <StyledButton
                        variant="primary"
                        className={'mr-xs mt-s'}
                        onPress={handleBulkSaveClick}
                      >
                        Save
                      </StyledButton>
                      <StyledButton
                        variant="secondary"
                        onPress={handlePopoverClose}
                      >
                        Cancel
                      </StyledButton>
                    </ButtonContainer>
                  </StyledCard>
                </div>
              )}
            </ManageEventPopover>
            <CalendarColorSelector
              data-test-id="hover-focus-panel"
              handleCalendarAccentColor={handleCalendarAccentColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
            <FontWrapper>
              <MainLabelStyles>Font</MainLabelStyles>
              <FormControl
                data-test-id="font-select-dropdown"
                id={'controlled-select'}
              >
                <FontSelect
                  onChange={handleFontChange}
                  value={props.value.fontFamily}
                  data-test-id="font-select"
                >
                  {Object.keys(getCombinedFontsForTheme()).map(f => {
                    return (
                      <option value={f} key={f}>
                        {f}
                      </option>
                    );
                  })}
                </FontSelect>
              </FormControl>
            </FontWrapper>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="sizeandlocation-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Background</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="background-panel"
          >
            <Background
              data-test-id="background-component"
              backgroundData={updateBackgroundData}
              backgroundImage={updateBackgroundImage}
              widgetState={widgetState}
              handleBackgroundColor={handleBackgroundColor}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-component"
              borderData={updateBorderData}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </div>
  );
};

export default CalendarWidgetControl;
